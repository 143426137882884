/*////////////////// REGISTER PAGE///////////////// */
.registerForm {
  width: 100%;
  display: inline;
  margin-top: 10%;
  text-align: left;
}
.detail {
  font-size: 14px;
}
.form-control {
  border-radius: 0px;
}

.registerbtn {
  background-color: #000;
  color: white;
  padding: 16px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
button,
select {
  text-transform: none;
}
button,
input {
  overflow: visible;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button {
  border-radius: 0;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
.registerContainer {
  width: 50%;
  margin-left: 45%;
  padding-top: 10%;
  padding-bottom: 2%;
  opacity: 1;
  z-index: 2;
}
.dot {
  height: 5px;
  width: 5px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 2px;
  margin-left: 1%;
  margin-top: 0;
}
.selectContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.sc3:not(:last-child) {
  margin-right: 5%;
}
.sc3 {
  width: 30%;
}
.selectItem {
  display: block;
  color: #000;
  text-align: center;
  padding: 20px 20px;
  text-decoration: none;
  background: #eee;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  line-height: 250%;
  transition: all 0.2s ease-in-out;
}
.selectItem:active,
.selectItemTrue,
.selectItem[selected="true"] {
  background-color: #000;
  outline: none;
  color: white;
}
.terms {
  font-weight: bold;
  text-align: left;
  margin-bottom: 2%;
  font-size: 16px;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
textarea {
  width: 100%;
  padding: 22px;
  margin: 2px 0 4px 0;
  display: inline-block;
  border: none;
  background: #eee;
  font-size: 14px;
}

li,
span {
  font-size: 14px;
  margin-bottom: 2%;
}
.date {
  padding: 2% 0 2% 0;
  text-align: center;
}
.padding {
  padding: 0 2% 2% 0;
  font-size: 16px;
}
.amount {
  margin-left: 0%;
  width: 50%;
  font-size: 14px;
  display: inline-block;
}
#logo {
  width: 40%;
  position: fixed;
  left: 4%;
  top: 28%;
  margin-bottom: 0%;
  margin-top: 0%;
}
.box {
  text-align: left;
  font-size: 18px;
  background-color: #fff;
  overflow: hidden;
  margin: 2%;
  padding: 6%;
  width: 95%;
  user-select: text;
}
#eventList {
  user-select: none;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  #logo {
    width: 70%;
    position: absolute;
    top: -50%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .registerContainer {
    width: 90%;
    margin: 0 auto;
    margin-top: 80%;
    padding-top: 0;
    padding-bottom: 2%;
    opacity: 1;
    z-index: 2;
  }
  .box {
    margin: 0 auto;
    margin-bottom: 10px;
    width: 90%;
    user-select: text;
  }
}

/*////////////////// checkmark box///////////////// */
label {
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  font-size: 14px;
}
li,
span {
  font-size: 14px;
  letter-spacing: normal;
  margin-bottom: 3%;
}
input[type="checkbox"] {
  display: inline-block;
  border: none;
  outline: none;
  background-color: #eee;
  color: #000;
  padding: 10px;
  margin: 5px;
  height: 20px;
  width: 20px;
}

.checkboxContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 10px;
}

/* Hide the browser's default checkbox */
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkboxContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkboxContainer input:checked ~ .checkmark {
  background-color: #000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkboxContainer span {
  font-weight: normal;
  text-transform: none;
  margin-top: 6px;
}
