.page {
  flex-direction: "row";
}

/* .section {
  margin: 10px;
  padding: 10px;
  flex-grow: 1px;
  top:0px;
  float:'left';
  left:0px;
} */

/*////////////////// COMMON ///////////////// */
.MainBg {
  opacity: 0.1;
  position: fixed;
  top: -25%;
  left: -20%;
  z-index: -10;
  width: 70%;
}

.carAnimationBg {
  background: rgb(238, 64, 52);
  background: linear-gradient(
    152deg,
    rgba(238, 64, 52, 1) 0%,
    rgba(156, 42, 100, 1) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.85;
}

/* Image{
  float: right;
  text-align: right;
} */

.barCodeFieldStyle input[type="number"],
input[type="text"] {
  border-radius: 8px;
  padding: 10px 15px;
  width: 15%;
  margin: 10px 4px 10px 4px;
  font-size: 14px;
  /* border: 1px solid white; */
  background: #f0f0f0ab;
}

.generate {
  border-radius: 8px;
  padding: 10px 15px;
  width: 30%;
  margin: 10px 4px 10px 4px;
  font-size: 14px;
  color: white;
  background: #000000;
}

.about {
  background-color: #e7f3fe;
  text-align: left;
  padding: 15% 18% 5% 18%;
  height: 820px;
}

.blob {
  display: inline;
  width: 150%;
  margin-right: -5%;
  margin-top: -10%;
}

.blob-motion {
  position: absolute;
  transform: translateY(-10%);
  z-index: 0;
}

.parallax {
  /* The image used */
  background-image: url("./images/ml/bg.png");
  /* Set a specific height */
  min-height: 900px;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.blobBox {
  text-align: left;
  /* padding: 5%; */
  display: inline-flex;
  width: 100%;
  /* padding: 5% 18% 6% 18%; */
}

.blue {
  /* background-color: #61bbef; */
  background-image: radial-gradient(
    circle 343px at 46.3% 47.5%,
    rgba(242, 242, 242, 1) 0%,
    rgba(241, 241, 241, 1) 72.9%
  );
  /* background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(234,249,249,0.67) 0.1%, rgba(239,249,251,0.63) 90.1% ); */
}

.purple {
  /* background-color: #9d8cf8; */
  background-image: linear-gradient(
    109.6deg,
    rgba(204, 228, 247, 1) 11.2%,
    rgba(237, 246, 250, 1) 100.2%
  );
}

.orange {
  /* background: linear-gradient(to right, #ffefba, #ffffff); */
  /* background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(234,249,249,0.67) 0.1%, rgba(239,249,251,0.63) 90.1% ); */
  background-image: radial-gradient(
    circle 343px at 46.3% 47.5%,
    rgba(242, 242, 242, 1) 0%,
    rgba(241, 241, 241, 1) 72.9%
  );
}

.blobBox div {
  font-size: 18px;
  font-weight: bold;
  margin: 10% 8% 10% 5%;
  color: darkgrey;
  width: 35%;
}

.blobby {
  fill: url(#grad1);
}

.mainPgInfo2 {
  background-color: #ffffff;
  text-align: left;
  padding: 5% 18% 7% 18%;
}

.mainPgInfo2 span {
  font-size: 17px;
  font-weight: normal;
}

.Fade-in {
  opacity: 1;
  -webkit-transition: all 0.4s ease-in;
  -moz-transition: all 0.4s ease-in;
  -ms-transition: all 0.4s ease-in;
  -o-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}

#title {
  width: 100%;
  z-index: 1;
  position: absolute;
  margin: 16.5% 0 0 0;
  /* margin-top: 16.5%; */
  /* left: 35%; */
}

.name {
  color: black;
  float: right;
  padding: 0 1% 0 0;
  font-size: 18px;
  font-weight: 600;
  user-select: none;
  cursor: default;
}

.betaTag {
  margin: 0 30px;
  color: red;
  font-weight: bold;
}

.profile-button {
  height: 35px;
  width: 35px;
  border-radius: 20px;
  background-color: black;
  padding-top: 5px;
  float: right;
  display: block;
  margin: 0;
  cursor: pointer;
  color: black;
}

.profile-button:hover {
  background-color: rgb(177, 22, 22);
  color: white;
}

/* .loginBox {
  padding: 0% 0 0 0;
  margin-top: 27.5%;
  margin-bottom: 2%;
} */
.loginButton {
  border-radius: 18px;
  background-color: black;
  padding: 0.5%;
  color: white;
  font-weight: 600;
  width: 10%;
  font-size: 16px;
  position: relative;
  float: right;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
}

.react-datepicker-wrapper {
  background-color: #2196f3;
  width: 100px;
  padding: 10px 15px;
}
.shadow {
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
}

.footer {
  background-color: #eee;
  padding: 20px 0px;
  font-size: 11px;
  text-align: left;
  /* padding: 2% 3%; */
  font-weight: normal;
  color: rgb(75, 75, 75);
  letter-spacing: normal;
  margin-top: 5rem;
}

.footer h6 {
  font-weight: 700;
  padding: 10px 0;
}

.footer a {
  color: rgb(92, 92, 92);
}

.footer p {
  padding: 2% 3%;
}

/* .footer a:hover{
  cursor: pointer;
  color: black;
  text-decoration: underline;
} */
.timer {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 20px;
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin-top: 20px;
}

/* custom radio btn */
.containerRadio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  text-transform: capitalize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.containerRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 3px;
  left: 9px;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.containerRadio:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.containerRadio input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerRadio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerRadio .checkmark:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

/* custom radio btn */

.registerbtn {
  background-color: #000;
  color: white;
  padding: 16px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 30%;
  opacity: 0.9;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.social {
  font-size: 24px;
  margin: 10px 20px 0 0;
}

.social:hover {
  color: skyblue;
}

.TopNav {
  z-index: 1000;
  padding: 13px;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
}

.mainCard {
  height: 25%;
  width: 95%;
  padding: 0;
  margin: auto;
  margin-top: -2px;
  margin-bottom: 16px;
  overflow: hidden;
  border-radius: 18px;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
}

ul {
  text-align: center;
  list-style-position: inside;
}

.bg-navbar {
  background-color: white;
  color: black;
}

.bg-navbar {
  background-color: white;
  color: black;
}

@media only screen and (max-width: 768px) {
  .profile-button {
    float: right;
  }

  .name {
    float: left;
    margin-left: 5%;
  }

  .betaTag {
    display: none;
  }

  .MainBg {
    top: 1%;
  }

  #title {
    height: "7%";
  }

  .mainCard {
    height: 220px;
    width: 90%;
    margin-top: 24%;
    border-radius: 18px;
    margin-bottom: 8px;
  }

  #title img[src="logo.png"] {
    margin-top: 90px;
    height: 70px;
  }

  .mainCard img:nth-of-type(1) {
    height: 230px;
    width: 230px;
  }

  .loginBox {
    padding: 37% 0 27% 0;
  }
}

/*////////////////// AR ///////////////// */
.arMainDiv {
  margin-top: 8%;
}

.arTitle {
  width: 32%;
}

@media only screen and (max-width: 768px) {
  .arMainDiv {
    margin-top: 18%;
  }

  .arTitle {
    width: 44%;
  }
}

/*////////////////// ANIMATION  ///////////////// */
.fadeBottom {
  -webkit-animation: fade-in-bottom 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.4s
    both;
  animation: fade-in-bottom 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.4s both;
}

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/*////////////////// FML PAGE///////////////// */

.mlTitle {
  padding-top: 6%;
  padding-bottom: 6%;
  width: 43%;
}

.fullBg {
  width: 100%;
  overflow: hidden;
  z-index: -10;
  position: fixed;
  left: 0;
  /* margin: auto; */
}

.reviews {
  display: inline-flex;
  width: 100%;
}

.ratingContainer {
  display: inline-block;
  margin-top: 10px;
  width: 55%;
}

.star {
  display: inline-flex;
  width: 100%;
}

.star > * {
  vertical-align: middle;
}

.starTitle {
  display: inline-block;
  margin-top: 10px;
  font-size: 20px;
  width: 55%;
  font-weight: 500;
  text-align: left;
}

.rating {
  width: 10%;
  font-size: 20px;
  margin-top: 10px;
  font-weight: bold;
  display: inline-block;
  /* margin: 10px 0 0 0; */
}

.award {
  height: 160px;
  width: 280px;
  font-family: "Times New Roman";
  font-weight: bold;
  margin-bottom: 20px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-Repeat;
}

.award:not(:last-child) {
  margin-right: 3%;
}

.award div {
  padding: 0 6%;
  word-wrap: normal;
  margin: 0 19%;
  /* width: 100%; */
  font-size: 18px;
}

.award h3 {
  padding-top: 7%;
  font-weight: bold;
}

.award h3:after {
  width: 100px;
  content: " ";
  display: block;
  background: black;
  height: 2px;
  margin: 0 auto;
  padding-bottom: 1%;
}

.Bg {
  width: 100%;
  height: 25%;
  z-index: 0;
  opacity: 0.5;
}

@media only screen and (max-width: 768px) {
  .reviews {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .ratingContainer {
    margin-left: 80px;
    width: 100%;
  }

  .star {
    display: inline-flex;
    flex-wrap: wrap;

    width: 100%;
  }

  .starTitle {
    display: inline-flex;
    margin-top: 10px;
    font-size: 17px;
    width: 100%;
    font-weight: 700;
    text-align: left;
  }

  /* .ratingContainer {
    margin: 6% 0 0% 0;
  }
  .star {
    display: inline-flex;
    width: 90%;
    margin-left: 0%;
  }

  .starTitle {
    display: inline-block;
    margin: 4% 3% 3% 0%;
    font-size: 16px;
    width: 50%;
    text-align: left;
  }

  .rating {
    width: 1%;
    display: inline-block;
    margin: 4% 0 3% 2%;
  }
  .award {
    text-align: center;
    width: 55%;
    margin: -2%;
  }
  .award h3 {
    text-align: center;
    font-size: 22px;
  }
  .award div {
    text-align: center;
    font-size: 15px;
  } */
}

.modalbox {
  padding: 50px;
}

/*////////////////// UPCOMING BOX///////////////// */

.upcomingEvents {
  padding: 0.5%;
  font-weight: 600;
  text-align: center;
  font-size: 26px;
  margin-top: 28px;
}

.info {
  width: 60%;
  border-radius: 18px;
  background: #f2f0f0;
  padding: 24px;
  margin: auto;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  text-align: left;
}

/* .info h3 {
  font-weight: bold;
} */
.info span {
  font-size: 17px;
}

.upcoming {
  width: 50%;
  border-radius: 18px;
  background: #f2f0f0;
  padding: 2% 0 1.5% 0;
  margin: 0 auto;
  margin-bottom: 34px;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
}

.upcoming div span {
  font-size: 17px;
}

.eventDetail {
  padding: 10px 16px;
}

.eventTitle {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  width: 100%;
  margin: -3.5% 0 1% 0;
  padding: 5px 0;
  color: white;
  display: inline-flex;
}

.event {
  display: inline-block;
  padding: 1% 0% 0% 3%;
  /* width: 70%; */
  text-align: left;
  display: flex;
  align-items: center;
}

.educational {
  padding-right: 1%;
  text-align: left;
}

.certified {
  padding-right: 2%;
}

.registerButton {
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
  color: white;
  border-radius: 9px;
  width: 35%;
  padding: 12px 7px;
  margin: 0 auto;
  margin-top: 12px;
  background: #000000;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
}

.registerButton:disabled {
  background-color: grey;
}

.dateBox {
  height: 10%;
  border-radius: 7px;
  color: black;
  padding: 8px 11px;
  margin: 7px 12px 9px 0;
  margin-left: auto;
  background: white;
  white-space: nowrap;
  display: inline-block;
}

.dateBox span {
  font-size: 17px;
}

.publicEvent {
  display: inline-block;
  margin: 1% 5% 1% -2%;
}

.siteMapBox {
  display: inline-block;
  /* padding:0 0 0 5%; */
  margin: 20px auto;
  width: 20%;
}

@media (prefers-color-scheme: dark) {
  .modal-header {
    background-color: rgb(52, 52, 53);
    color: white;
    border-bottom-color: grey;
  }

  .name {
    color: white;
  }

  .modal-body,
  .bg-navbar,
  .profile-button {
    background-color: rgb(52, 52, 53);
    color: white;
  }

  .upcoming,
  .footer {
    background-color: rgb(34, 33, 33);
    color: darkgrey;
  }

  .footer a {
    color: grey;
  }
}

@media only screen and (max-width: 768px) {
  .info {
    width: 90%;
  }

  .info span {
    font-size: 14px;
  }

  .upcomingEvents {
    font-weight: 500;
    font-size: 22px;
    margin-bottom: -10px;
  }

  .upcoming {
    width: 90%;
    margin-top: 15px;
    margin-bottom: 32px;
  }

  .event {
    font-size: 20px;
    width: 100%;
    padding: 10px 0 5px 0;
    justify-content: center;
    text-align: center;
    font-weight: 500;
  }

  .eventTitle {
    display: block;
  }

  .upcoming div span {
    font-size: 14px;
  }

  .registerButton {
    font-size: 17px;
    margin-bottom: 20px;
    width: 85%;
  }

  .dateBox {
    margin: 0 auto;
    margin-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;
  }
}

/*////////////////// DOWNLOAD ///////////////// */

/* input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
textarea {
  width: 100%;
  padding: 12px;
  margin: 6px 0;
  display: inline-block;
  border: none;
  background: #eee;
  font-size: 14px;
} */
.downloadbtn {
  background-color: #000;
  color: white;
  padding: 16px 0px;
  right: 0;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.download-box {
  border-radius: 10px;
  padding: 0% 3% 3% 3%;
  letter-spacing: 1px;
  width: 28%;
  margin: 0 auto;
  background-color: white;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
}

.downloadCard {
  width: 300px;
  margin-left: -12%;
}

.loginSticky {
  background-color: rgba(1, 1, 1, 0);
  color: black;
  /* padding: 10px 16px; */
  border-radius: 10px;
  /* margin: 8px 0 0 0; */
  /* margin-top: 8px; */
  border: 3px solid black;
  cursor: pointer;
  opacity: 1;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.loginSticky:hover {
  background-color: #000;
  color: white;
}

.subbtn {
  background-color: rgba(1, 1, 1, 0);
  color: black;
  padding: 10px 16px;
  border-radius: 10px;
  margin: 8px 0 0 0;
  margin-top: 8px;
  border: 3px solid black;
  cursor: pointer;
  opacity: 1;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.subbtn:hover {
  background-color: #000;
  color: white;
}

.alert {
  margin: 5% 0 0 0;
  text-align: left;
}

.referBox {
  /* width: 40px; */
  font-size: 17px;
  border-width: 0;
}

.refer {
  background-color: rgba(4, 161, 64, 0);
  color: rgb(0, 114, 28);
  padding: 10px 16px;
  border-radius: 9px;
  /* margin: auto; */
  margin: -20px 30px 0 0;
  float: right;
  text-align: center;
  border: 3px solid rgb(0, 138, 23);
  cursor: pointer;
  opacity: 1;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.refer:hover {
  background-color: rgb(0, 128, 38);
  color: white;
}

@media (prefers-color-scheme: dark) {
  .referBox {
    background-color: #182e18;
    color: #c3d8ce;
  }

  .refer {
    color: #c3d8ce;
    padding: 10px 16px;
    border-radius: 8px;
    /* margin: auto; */
    margin: -20px 30px 0 0;
    float: right;
    text-align: center;
    border: 2px solid #c3d8ce;
    cursor: pointer;
    opacity: 1;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .refer:hover {
    background-color: #c3d8ce;
    color: #182e18;
  }

  .about {
    background-color: black;
  }

  .info {
    background-color: rgb(34, 33, 33);
    color: white;
  }
}

.username {
  margin: 9% 0 2% 0;
}

.galleryContainer {
  position: relative;
  top: 0;
  left: 0;
}

.galleryBox {
  padding: 0 70px;
}

.loadMore {
  background-color: #eee;
  height: 180px;
  position: absolute;
  top: 51%;
  right: 4%;
  float: right;
  width: 240px;
}

.galleryImg {
  object-fit: cover;
  cursor: pointer;
}

/* .galleryImg:hover{
  height: 187;
  width: 250;
  transform: scale(2);
  border: 5px solid black;
} */
.galleryTitle {
  text-align: left;
  margin-left: 10px;
  margin-top: 40px;
  font-weight: 500;
  font-size: 20px;
}

.gallerySubtitle {
  text-align: left;
  margin-left: 10px;
  font-size: 16px;
  color: darkgrey;
}

.receipt {
  margin: 7% 30% 0 0;
  float: right;
  position: relative;
  height: 700px;
}

.receiptLogo {
  /* margin-bottom: 20px; */
  height: 25px;
}

.courseLogo {
  margin: -40px 0 0 0;
  /* margin-bottom: 20px; */
  height: 230px;
}

.tickLogo {
  height: 120px;
  margin-top: -4px;
}

.receipt svg {
  position: absolute;
  z-index: 1;
}

.qrcode {
  margin-top: 5px;
  margin-bottom: 8px;
  border-radius: 7px;
}

.receiptText {
  /* margin:50px 40px; */
  width: 292px;
  margin-top: 40px;
  /* margin-left: 80px; */
  /* color: white; */
  z-index: 2;
  position: absolute;
  /* margin:  0 auto; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* margin-top: 2%; */
  /* z-index: 100; */
  /* position: relative; */
}

.receiptBlueBox {
  /* background-color: #e7f3fe; */
  justify-content: center;
  height: 820px;
  width: 65%;
}

.receiptBlueBox h3 {
  margin-bottom: 15px;
}

.receiptBlueBox span {
  font-size: 17px;
  line-height: 32px;
}

.tick {
  height: 120px;
  /* margin: 15% 15% 0 0; */
  float: right;
  position: absolute;
  /* height: 700px; */
}

.spacer {
  aspect-ratio: 960/300;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* .paymentText { */
/* color: white; */
/* padding: 30px 0 10px 0; */
/* } */
/* .goToDashboardBtn{
  width:30%;
} */
/* grad{
  background: rgb(34,193,195);
background: linear-gradient(292deg, rgba(34,193,195,1) 0%, rgba(45,100,253,1) 100%);
} */
.ticket {
  fill: white;
  /* fill: url(#grad1); */
}

.layer1 {
  background-image: url("./images/reciept/wave31.svg");
  margin-top: 30px;
}

.payVector {
  height: 220px;
  margin: 22% 0 7% 0;
}

@media only screen and (max-width: 768px) {
  .layer1 {
    background-image: none;
  }

  .alert {
    margin: 70px 0 0 0;
    height: 150px;
  }

  .download-box {
    border-radius: 10px;
    padding: 3% 3% 3% 3%;
    letter-spacing: 1px;
    width: 70%;
  }

  .downloadCard {
    width: 250px;
    margin-left: 0%;
  }

  .username {
    margin: 33% 0 8% 0;
  }

  .refer {
    width: 28%;
    padding: 6px 9px;
    border-radius: 7px;
    margin: 10px 0 0 0;
    align-items: center;
    float: none;
    font-size: 12px;
  }
}
