/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.card-container {
  display: inline-flex;
  margin: 0;
  margin-bottom: 28px;
  margin-top: 28px;
}

.flip-card {
  padding: 0 3%;
  background-color: transparent;
  width: 327px;
  height: 400px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}
.flip-card h3 {
  font-weight: 500;
}
/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 18px;
  text-align: center;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
}

/* Position the front and back side */
.flip-card-front {
  position: absolute;
  width: 100%;
  padding: 25px;
  border-radius: 18px;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #f2f0f0;
  color: black;
  display: flex;
  text-align: left;
  align-items: center;
}
.flip-card-front span li {
  font-size: 15.5px;
}

@media (prefers-color-scheme: dark) {
  .flip-card-front {
    background-color: rgb(52, 52, 53);
    color: white;
  }
}

@media only screen and (max-width: 768px) {
  .flip-card {
    width: 300px;
    height: 370px;
  }
  .flip-card-front span li {
    font-size: 14.25px;
  }
  .flip-card-front {
    padding-top: 30px;
  }
}
