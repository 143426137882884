.preview {
  width: 20%;
  margin-top: 2%;
  display: inline-flex;
  opacity: 1;
  cursor: pointer;
}

.circle-container {
  display: inline-block;
}

.arrow {
  font-size: 24px;
  margin-left: 1%;
  margin-top: 0.5%;
}

.track {
  display: inline-block;
  width: 30%;
  /* border: 1px solid black;   */
  margin: auto;
}

.trophy {
  color: rgb(247, 222, 0);
  font-size: 18px;
  float: right;
  top: 33px;
}

.circle {
  height: 60px;
  width: 60px;
  border-radius: 35px;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  color: white;
  font-size: 30px;
  padding-top: 12%;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.container {
  border-radius: 9px;
  padding: 2%;
  margin: auto;
  width: 90%;
  background-color: white;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
}

.ended-container {
  padding: 2% 20%;
  margin: auto;
  width: 75%;
  background-color: white;
}

.attachment {
  text-align: left;
  border-left: 8px solid#5e09b8;
  border-radius: 9px;
  padding: 2%;
  width: 65%;
  margin: 0 0 25px 19px;
  background-color: white;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
}

.calendarInfo {
  text-align: left;
  border-left: 8px solid#5e09b8;
  border-radius: 9px;
  padding: 2%;
  width: 95%;
  background-color: white;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
}

.calendarInfoAgenda {
  text-align: left;
  border-left: 8px solid#5e09b8;
  border-radius: 9px;
  padding: 10px;
  margin: 0 20px;
  width: 95%;
  background-color: white;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
}

.documentInfo {
  display: inline-block;
  text-align: left;
  border-left: 8px solid#5e09b8;
  /* margin-right: 5%; */
  border-radius: 10px;
  padding: 2%;
  margin: 2%;
  width: 50%;
  background-color: white;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
}

.dropzone {
  color: grey;
  height: 150px;
  border: 1px dashed grey;
  text-align: center;
  padding-top: 65px;
  font-size: 14px;
  user-select: none;
  cursor: pointer;
}

.dropzone:hover {
  background-color: #eee;
}

.gMeetButton {
  height: 45px;
  width: 45px;
  border-radius: 22px;
  background-color: rgb(235, 235, 235);
  float: right;
  display: block;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  margin: 0;
  margin-top: -22px;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: black;
    color: white;
  }

  .container,
  .documentInfo,
  .attachment {
    background-color: rgb(34, 33, 33);
    color: white;
  }

  .vid-container,
  .calendarInfo,
  .calendarBox,
  .gCalButton,
  .calendarInfoAgenda {
    background-color: rgb(52, 52, 53);
    color: white;
  }

  .ended-container {
    background-color: black;
  }

  .dropzone {
    color: grey;
    height: 150px;
    border: 1px dashed grey;
    text-align: center;
    padding-top: 65px;
    font-size: 14px;
    user-select: none;
    cursor: pointer;
  }

  .dropzone:hover {
    background-color: rgb(68, 66, 66);
  }

  .gMeetButton {
    background-color: rgb(82, 82, 83);
  }
}

/* .documentInfo :not(:last-child){
  display: inline-block;
  margin-right: 5%;
} */
.gMeetLogo {
  float: right;
  margin: 4px 3px 0 0;
  height: 35px;
}

.vidModal {
  width: 90%;
}

.time {
  float: right;
  border-radius: 4px;
  color: black;
  width: 30%;
  padding: 1%;
  text-align: center;
  margin-top: -19px;
  background: #f2f0f0;
  vertical-align: auto;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
}

.checkBoxStyle {
  font-weight: normal;
  margin: 5px;
  font-size: 16px;
}

.registerButton {
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
  color: white;
  border-radius: 9px;
  width: 35%;
  padding: 12px 7px;
  margin: 0 auto;
  margin-top: 12px;
  background-color: #000000;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
}

.registerButton:disabled {
  background-color: grey;
}

.form-check {
  padding: 0;
}

.sessionTitle {
  width: 90%;
}

.download-button {
  font-weight: 600;
  float: right;
  border-radius: 20px;
  color: white;
  padding: 1% 3%;
  background: #474747;
  vertical-align: auto;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border-radius: 10px;
  border: 0;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.sessionsContainer {
  margin-left: 10px;
  overflow-x: scroll;
  width: auto;
  white-space: nowrap;
}

.vidTitle {
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /* number of lines to show */
}

.player-video {
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
}

.vidDescription {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* number of lines to show */
}

.pressable {
  cursor: pointer;
  user-select: none;
  transition: ease-in-out 0.3s;
}

.vid-container {
  display: inline-block;
  width: 330px;
  border-radius: 9px;
  margin: 20px 12px;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  text-align: left;
}

.pressable:hover {
  transform: scale(1.05);
}

.vid-thumbnail-container {
  height: 185px;
  width: 330px;
  border-radius: 9px 9px 0 0;
  overflow: hidden;
}

.vid-text-container {
  margin: 18px;
  margin-top: 15px;
}

.profile {
  display: inline-flex;
  width: 95%;
  cursor: pointer;
}

.profileName {
  display: inline-block;
  width: 30%;
  margin-top: 0.5%;
  text-align: left;
}

.progressProfile {
  width: 70%;
  padding: "7px 0 0 0";
  margin-top: 1%;
}

.progressInfo {
  height: 70px;
  overflow-y: scroll;
  display: inline-block;
}

.projectPhase {
  width: 85%;
  display: inline-block;
  margin: 0 0 0 9%;
}

.gCalButton {
  display: inline-block;
  width: 330px;
  border-radius: 9px;
  margin: 20px 12px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
  justify-content: center;
  padding: 10px 15px;
  font-weight: 500;
  color: grey;
}

.projectProgressContainer {
  display: inline-flex;
  flex-wrap: wrap;
  padding-left: 30%;
  width: 100%;
}

.impUpdate {
  margin: 0 2%;
}

.smallText {
  display: inline-block;
  width: 90%;
  font-size: 14px;
  color: darkgrey;
}

.headings {
  padding: 1%;
  margin-top: 40px;
}

.calendarBox {
  display: inline-block;
  width: 30%;
  margin-right: 0;
}

.nextSessions {
  display: inline-block;
  width: 70%;
  margin-top: 3%;
}

@media only screen and (max-width: 768px) {
  .calendarInfo {
    flex-wrap: wrap;
    padding: 15px 15px 30px 15px;
    width: 100%;
  }

  .calendarInfoAgenda {
    margin: 0 5px;
  }

  .gCalButton {
    flex-wrap: wrap;
    width: 90%;
  }

  .vidTitle {
    font-size: 14px;
  }

  .vidDescription {
    font-size: 12px;
  }

  .sessionsContainer {
    margin-left: 0px;
  }

  .time {
    width: 160px;
    font-size: 14px;
    margin-top: 8px;
    padding: 1px;
  }

  .smallText {
    font-size: 12px;
  }

  .profile {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .nextSessions {
    width: 100%;
    padding: 10px 0;
  }

  .calendarBox {
    display: inline-block;
    width: 100%;
    margin-left: 1px;
    margin-bottom: 25px;
  }

  .headings {
    margin-top: 45px;
  }

  .arrow {
    margin-left: 18px;
    margin-top: -15px;
  }

  .profileName {
    display: inline-block;
    width: 80%;
    margin-top: 2%;
    margin-left: 30px;
    text-align: left;
  }

  .progressProfile {
    width: 90%;
    padding: "7px 0 0 0";
    margin-top: -10px;
  }

  .progressInfo {
    margin: 40px 0 30px 0;
    width: 100%;
    font-size: 14px;
  }

  .projectPhase {
    width: 95%;
    display: inline-block;
    margin: 0 0 0 0;
  }

  .projectProgressContainer {
    padding-left: 0%;
    width: 90%;
    margin-left: -18px;
  }

  .vid-container {
    width: 100%;
  }

  .sessionTitle {
    font-size: 14px;
  }

  .impUpdate {
    font-size: 14px;
    margin: 10px 20px 20px 20px;
  }
}
