.login-bg {
  background-color: #0093e9;
  background-image: linear-gradient(135deg, #003feb 0%, #80b9d0 100%);
  height: 100%;
  width: 100%;
  text-align: center;
}

.loginTitle {
  margin-top: 10%;
}

.title {
  color: white;
  font-weight: bolder;
  padding: 5% 0;
}

.loginDescr {
  font-size: 17px;
  width: 65%;
  margin: 0 auto;
  margin-top: 1%;
  font-weight: normal;
}

.join {
  color: #478fec;
  font-size: 14px;
  cursor: pointer;
}

.loginGradient {
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  background: rgb(224, 236, 254);
  display: "inline-block";
  width: 45%;
  padding: 14% 3%;
}

.loginFields {
  display: "inline-block";
  width: 55%;
  height: 100%;
  padding: 3% 5%;
}

.login-container {
  text-align: center;
  opacity: 1;
  z-index: 100;
  margin: auto;
  margin-top: 4%;
  margin-bottom: 5%;
  width: 50%;
  height: 400px;
  background-color: white;
  color: black;
  border-radius: 9px;
  padding: 0;
  display: inline-flex;
  -webkit-box-shadow: 0px 0px 13px 5px rgba(0, 0, 0, 0.17);
  box-shadow: 0px 0px 13px 5px rgba(0, 0, 0, 0.17);
}

.lookup-container {
  text-align: center;
  margin: auto;
  margin-top: 2%;
  margin-bottom: 3%;
  width: 30%;
  background-color: white;
  color: black;
  border-radius: 9px;
  padding: 4%;
  -webkit-box-shadow: 0px 0px 13px 5px rgba(0, 0, 0, 0.17);
  box-shadow: 0px 0px 13px 5px rgba(0, 0, 0, 0.17);
}

.field {
  margin: 3% auto;
  border-radius: 8px;
}

.submitLogin {
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
  color: white;
  border-radius: 9px;
  width: 100%;
  padding: 12px 7px;
  margin: 0 auto;
  margin-top: 12px;
  background: #000000;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
}

.login-alert {
  color: rgb(212, 5, 33);
  font-size: small;
  font-weight: 600;
}

@media (prefers-color-scheme: dark) {
  .login-container,
  .lookup-container {
    background-color: rgb(52, 52, 53);
    color: white;
  }

  .loginGradient,
  .loginFields input,
  .loginFields input:focus {
    background-color: rgb(34, 33, 33);
    color: white;
  }
}

.h3-title {
  margin-top: 15%;
  letter-spacing: 1px;
}

@media only screen and (max-width: 768px) {
  .login-container {
    width: 80%;
    border-radius: 9px;
    /* margin-bottom: -60px; */
    display: inline-flex;
    flex-direction: column;
    /* padding: 7% 7%; */
    height: fit-content;
    -webkit-box-shadow: 0px 0px 13px 5px rgba(0, 0, 0, 0.17);
    box-shadow: 0px 0px 13px 5px rgba(0, 0, 0, 0.17);
  }

  .h3-title {
    margin-top: 25%;
  }

  .lookup-container {
    width: 80%;
    padding: 10% 8%;
  }

  .field {
    margin: 77px;
    border-radius: 8px;
  }

  .loginTitle {
    margin-top: 100px;
  }

  .loginDescr {
    font-size: 14px;
    width: 90%;
    margin: 0 auto;
    margin-top: 1%;
    font-weight: normal;
  }

  .loginGradient {
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    border-bottom-left-radius: 0px;
    background: rgb(224, 236, 254);
    /* flex-direction: column; */
    width: 100%;
    padding: 14% 3%;
  }

  .loginFields {
    /* display: "inline-block"; */
    /* flex-direction: column; */
    width: 100%;
    /* height: 100%; */
    padding: 10px 30px 30px 30px;
  }
}
