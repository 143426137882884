.single-chart {
  width: 45%;
  display: inline-block;
  justify-content: space-around;
  margin: 0;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
  margin-top: -57px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 2.8;
  transform: scale(0.8);
}

.circle {
  fill: none;
  stroke-width: 3;
  animation: progress 1s ease-out forwards;
  animation-delay: 2s;
  stroke-linecap: butt;
  transform: scale(0.8);
}

.circle-title {
  margin-top: -65px;
  font-weight: bold;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart.blue .circle {
  stroke: #1c56b8;
}

.percentage {
  fill: #666;
  font-family: sans-serif;
  font-size: 5px;
  font-weight: bold;
  transform: scale(0.8);
  text-anchor: middle;
}

@media (prefers-color-scheme: dark) {
  .circular-chart {
    background-color: black;
  }
}

@media only screen and (max-width: 768px) {
  .single-chart {
    padding-top: 40px;
    width: 100%;
    justify-content: space-around;
    transform: scale(0.8);
    margin: 0 auto;
  }

  .circular-chart {
    padding-left: 50px;
  }

  /* .single-chart {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin:0;
  } */
  /* .single-chart {
    width: 60%;
    margin: -10% -3% 0% 16%;
  }

  .circular-chart {
    margin-left: 16%;
  } */
}
